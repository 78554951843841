import React from "react"
import "../styles/sending.scss"

const Sending = () => {
  return (
    <div className="sending-container">
      <div className="lds-dual-ring"></div>
      <p>Redirecting to Google Forms...</p>
    </div>
  )
}

export default Sending