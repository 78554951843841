import React from "react"
//importing winbox https://github.com/nextapps-de/winbox/issues/1
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer
      style={{ display: "flex", flexDirection: "column", margin: "auto" }}
    >
      <div style={{ margin: "auto" }}>
        <a
          href="https://www.linkedin.com/in/akshayyyy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Linkedin
        </a> {" | "}
        <a
          href="https://www.github.com/AkshayO3"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a> {" | "}
        <a
          href="https://drive.google.com/file/d/1aOzZob1fSIP9TXfm3t96qTtqpXiOL4ac/view?usp=drive_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Resume
        </a> {" | "}
        <a
          href="https://forms.gle/2zruBRZSWTHSof1T6"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact
        </a>
      </div>
      <span
        style={{
          margin: "auto",
          fontSize: "xx-small",
          textAlign: "center",
          paddingBottom: "10px",
        }}
      >
        © 2022-{new Date().getFullYear()} Akshay Bhandari
      </span>
    </footer>
  )
}


export default Footer
