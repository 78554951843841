// src/components/Contact.js
import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import Sending from "./Sending"

const Contact = () => {
  const [isRedirecting, setIsRedirecting] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("https://forms.gle/fkC3HoDSMUFtpeaS8")
    }, 2000) // Adjust the delay as needed

    return () => clearTimeout(timer)
  }, [])

  return isRedirecting ? <Sending /> : null
}

export default Contact